<template>
    <div id="photography">
        <inc_header></inc_header>

        <div class="container">
            <div class="banner"><el-image :src="banner.srcPc" fit="cover"></el-image></div>

            <div class="show">
                <section>
                    <h2>模特 <span>MichelleDance models</span></h2>
                    <div class="area">
                        <dl v-for="m in models" :key="m.photographyId">
                            <dt><router-link :to="{ name:'photography_show', params:{ id:m.photographyId } }" target="_blank"><el-image :src="m.thumbnail" fit="cover"></el-image></router-link></dt>
                            <dd>
                                <span>模特 Models</span>
                                <router-link :to="{ name:'photography_show', params:{ id:m.photographyId } }" target="_blank">{{ m.name }}</router-link>
                                <div v-html="m.summary"></div>
                            </dd>
                        </dl>
                    </div>
                </section>
                <section>
                    <h2>摄影师 <span>MichelleDance photographers</span></h2>
                    <div class="area">
                        <dl v-for="p in photographers" :key="p.photographyId">
                            <dt><router-link :to="{ name:'photography_show', params:{ id:p.photographyId } }" target="_blank"><el-image :src="p.thumbnail" fit="cover"></el-image></router-link></dt>
                            <dd>
                                <span>摄影师 Photographer</span>
                                <router-link :to="{ name:'photography_show', params:{ id:p.photographyId } }" target="_blank">{{ p.name }}</router-link>
                                <div v-html="p.summary"></div>
                            </dd>
                        </dl>
                    </div>
                </section>
            </div>
        </div>

        <inc_footer></inc_footer>
    </div>
</template>

<style scoped>
    .banner { height:480px; }
    .banner .el-image { width:100%; height:100%; }

    .show { margin:60px 0; }
    .show:after { height:0; display:block; content:''; clear:both; visibility:hidden; }
    .show section { width:580px; height:auto; margin:0 0 0 40px; float:left; }
    .show section:first-child { margin-left:0; }
    .show section h2 { margin:0 0 40px 0; font-size:24px; font-weight:400; color:#000; }
    .show section h2 span { color:#999; }
    .show section:first-child h2 span { color:#a00e15; }
    .show section .area { width:620px; height:auto; overflow:hidden; }
    .show section .area:after { height:0; display:block; content:''; clear:both; visibility:hidden; }
    .show section .area dl { width:270px; height:auto; margin:0 40px 30px 0; float:left; }
    .show section .area dl dt { height:360px; }
    .show section .area dl dt .el-image { width:270px; height:360px; }
    .show section .area dl dd { padding:10px 0 0 0; margin:0; color:#999; }
    .show section .area dl dd span { color:#999; display:block; }
    .show section:first-child .area dl dd span { color:#a00e15; }
    .show section .area dl dd a { margin:0 0 10px 0; font-size:16px; color:#000; text-decoration:none; display:block; }
    .show section .area dl dd a:hover { text-decoration:underline; }
</style>

<script>
    import inc_header from "../components/inc_header";
    import inc_footer from "../components/inc_footer";

    export default {
        name: "photography",
        components: {
            inc_header,
            inc_footer
        },
        data() {
            return {
                banner: {},  // Banner
                models: [],  // 模特
                photographers: []  // 摄影师
            }
        },
        created() {
            let that = this;

            // 获取“Banner”
            this.$axios.post(this.$global.config.serverApi+'/banner.php',{ action:"getByCategory", category:"photography" }).then( function(response) {
                if (response.data.complete === true) {
                    that.banner = response.data.banner;
                } else {
                    throw new Error(response.data.error);
                }
            }).catch( function(error) {
                that.$store.commit('setAlert', error.message);  // 弹出错误提示
            });

            // 获取“模特”
            this.$axios.post(this.$global.config.serverApi+'/photography.php',{ action:"list", category:1, page:0, rowsPerPage:0 }).then( function(response) {
                if (response.data.complete === true) {
                    that.models = response.data.photography;
                } else {
                    throw new Error(response.data.error);
                }
            }).catch( function(error) {
                that.$store.commit('setAlert', error.message);  // 弹出错误提示
            });

            // 获取“摄影师”
            this.$axios.post(this.$global.config.serverApi+'/photography.php',{ action:"list", category:2, page:0, rowsPerPage:0 }).then( function(response) {
                if (response.data.complete === true) {
                    that.photographers = response.data.photography;
                } else {
                    throw new Error(response.data.error);
                }
            }).catch( function(error) {
                that.$store.commit('setAlert', error.message);  // 弹出错误提示
            });
        }
    }
</script>
